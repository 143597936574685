<!--
 * @Description: 
 * @Author: hanyu
 * @Date: 2020-09-21 16:20:57
 * @LastEditTime: 2020-09-21 16:22:02
 * @LastEditors: hanyu
-->
<template>
  <div class="container">
    <div v-if="error.statusCode === 404">
      <h1>Page not found</h1>
      <p>{{ error.message }}</p>
    </div>
    <div v-else>
      <h1>An error occurred</h1>
    </div>
    <nuxt-link to="/">首页</nuxt-link>
  </div>
</template>

<script>
export default {
  props: ['error'],
  layout: 'default' // If you prefers you can set a custom layout for the error page
}
</script>