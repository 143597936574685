/*
 * @Author: lihaifan 
 * @Date: 2020-07-27 20:56:36 
 * @Last Modified by: lihaifan
 * @Last Modified time: 2020-07-27 21:07:39
 */
import Vue from 'vue'
import lodash from 'lodash';

const smartLodash = {
    install(Vue) {
        Vue.prototype.lodash = lodash
    }
}

Vue.use(smartLodash);