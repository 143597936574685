import cookie from '@/lib/cookie.js';

export const state = () => ({
  majorInfo: null
});
export const getters = {

};
export const mutations = {
  CLEAR_MAJOR_INFO (state) {
    state.majorInfo = null;
  },
  // 设置专业信息
  SET_MAJOR_INFO (state, data) {
    state.majorInfo = data;
  }
};
