<!--
 * @Description: 注册
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-26 17:01:44
 * @LastEditors: hanyu
 * @LastEditTime: 2020-12-30 09:50:40
-->
<template>
  <div>
    <div class="title"
      style="margin-bottom:30px;">
      用户注册
    </div>
    <div class="form">
      <div class="form-row phone">
        <div class="input-conent">
          <Input v-model.trim="formData.phone"
            placeholder="请输入手机号码"
            maxlength="11" />
        </div>
      </div>
      <div class="form-row sms">
        <div class="input-conent">
          <Input v-model.trim="formData.smsCode"
            placeholder="请输入验证码"
            maxlength="6" />
        </div>
        <div class="get-sms"
          :class="{disable: disableGetBut}"
          @click="sendVerify">
          {{ getSmsTips }}
        </div>
      </div>
      <div class="form-row pass">
        <div class="input-conent">
          <Input v-model.trim="formData.password"
            type="text"
            :style="{'webkit-text-security' : passHide ? 'disc' : 'unset'}"
            placeholder="请输入密码" />
        </div>
        <div class="toggle-pass"
          :class="{show: !passHide}"
          @click="passHide = !passHide" />
      </div>
      <div class="send-button"
        @click="registerValidate">
        注 册
      </div>
    </div>
    <div class="to-login">
      已有账号？<a href="javascript:;"
        @click="changeLoginType">登录</a>
    </div>

    <Spin v-if="isLoading"
      fix>
      <Icon type="ios-loading"
        size="36"
        class="demo-spin-icon-load" />
    </Spin>
  </div>
</template>

<script>
import { commonApi } from '@/api/common';
import { accountApi } from '@/api/account';
import { regular } from '@/lib/regular';
import {
  SMS_VERIFY_ACTION_TYPE_ENUM,
  SMS_VERIFY_TYPE_ENUM
} from '@/constant/common';
import {
  ACCOUNT_SHOW_TYPE,
  TERMINAL_TYPE_ENUM,
  DEVICE_TYPE_ENUM
} from '@/constant/account';
import { getGeo } from '@/lib/geo';
import { getSystemName, getBrowserName } from '@/lib/utils';
import { USER_TERMINAL_APP_TYPE_ENUM, USER_DEVICE_TYPE_ENUM } from '@/constant/account';

export default {
  name: 'AccountRegister',
  components: {},
  filters: {},
  props: {},
  data () {
    return {
      formData: {
        phone: null,
        smsCode: null,
        password: null
      },
      passHide: true,
      isLoading: false,
      getSmsTips: '获取验证码',
      disableGetBut: false,
      countDownTimer: null // 定时器
    };
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  beforeDestroy () {
    this.unListenEnterKeyDown();
  },
  methods: {
    // 监听回车按下事件
    listenEnterKeyDown () {
      document.onkeydown = (e) => {
        const event = e || event;
        if (event.keyCode == 13) {
          event.preventDefault();
          this.registerValidate();
        }
      };
    },
    // 撤销监听回车按下事件
    unListenEnterKeyDown () {
      document.onkeydown = undefined;
    },
    // 切换到登录
    changeLoginType () {
      this.$store.commit(
        'user/changeAccountType',
        ACCOUNT_SHOW_TYPE.LOGIN_PASS.value
      );
    },

    // 发送验证码
    async sendVerify () {
      if (this.disableGetBut) {
        return;
      }
      try {
        this.isLoading = true;
        if (!this.formData.phone) {
          this.$Message.error('请输入手机号码');
          return;
        }
        if (!regular.phone.test(this.formData.phone)) {
          this.$Message.error('手机号码不正确');
          return;
        }
        const data = {
          phone: this.formData.phone,
          actionType: SMS_VERIFY_ACTION_TYPE_ENUM.SMS_VERIFY_REGISTER.value,
          verifyType: SMS_VERIFY_TYPE_ENUM.REGISTER.value
        };
        console.log(data);
        await commonApi.sendVerify(data);
        this.runCountDown();
        this.$Message.success('验证码已发送');
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },

    // 开始倒计时
    runCountDown () {
      this.disableGetBut = true;
      let countDown = 60;
      this.getSmsTips = `${countDown}秒重新获取`;
      this.countDownTimer = setInterval(() => {
        if (countDown > 1) {
          countDown--;
          this.getSmsTips = `${countDown}秒重新获取`;
        } else {
          clearInterval(this.countDownTimer);
          this.disableGetBut = false;
          this.getSmsTips = '获取验证码';
        }
      }, 1000);
    },

    // 点击注册，验证注册信息
    registerValidate () {
      if (!this.formData.phone) {
        this.$Message.error('请输入手机号码');
        return;
      }
      if (!regular.phone.test(this.formData.phone)) {
        this.$Message.error('手机号码不正确');
        return;
      }
      if (!this.formData.smsCode) {
        this.$Message.error('请输入验证码');
        return;
      }
      if (!this.formData.password) {
        this.$Message.error('请输入密码');
        return;
      }
      if (!regular.pass.test(this.formData.password)) {
        this.$Message.error('请输入6-20位字母、数字或下划线的密码');
        return;
      }
      this.register();
    },

    // 注册API
    async register () {
      try {
        this.isLoading = true;
        // 增加 经纬度、终端类型、设备类型 by 李晓东
        const geoInfo = getGeo();
        const geoArr = geoInfo.userGeo.split(',') || ['', ''];
        // 设备信息
        const systemName = getSystemName();
        // 浏览器名称
        const browserName = getBrowserName();
        const params = {
          ...this.formData,
          latitude: geoArr[0],
          longitude: geoArr[1],
          deviceType: USER_DEVICE_TYPE_ENUM.PC.value,
          deviceInfo: `${systemName}${browserName}`,
          terminalAppType: USER_TERMINAL_APP_TYPE_ENUM.PC_ZHI_KAO_DA_XUE.value
        };
        const result = await accountApi.register(params);
        console.log(result);
        this.$emit('loginSuccess', result.data);
        this.$Message.success('注册成功');
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },
    // 重置参数
    resetParams () {
      clearInterval(this.countDownTimer);
      Object.assign(this.$data, this.$options.data.call(this));
    }
  }
};
</script>
<style lang='less' scoped>
@import './account.less';
.to-login {
  margin-top: 15px;
  text-align: right;
  a {
    color: @main-color;
  }
}
</style>
