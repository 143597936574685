/*
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-23 16:31:32
 * @LastEditors: hanyu
 * @LastEditTime: 2020-11-12 10:46:17
 */

import Vue from 'vue';
import Axios from 'axios';
import cookie from '@/lib/cookie';
import { Message, Spin } from 'view-design';
import { ACCOUNT_SHOW_TYPE } from '@/constant/account';
import { getGeo } from './geo';

// 是否nuxt服务端请求
const isServer = Vue.prototype.$isServer;
// console.log('是否服务端请求：', isServer)
let baseURL = 'http://zrjy.cloud.renminyixue.cn/api/manage-app';
if (!isServer && process.env.NUXT_ENV_MODE != 'local' && process.env.NUXT_ENV_MODE != 'dev' ) {
  baseURL = `${window.location.origin}/api/manage-app`
}
console.log(`http中的baseURL${baseURL}`)
const axios = Axios.create({
  // baseURL: process.env.baseUrl,
  baseURL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
});
// 添加请求拦截器
axios.interceptors.request.use(
  // 在发送请求之前做些什么
  (config) => {
    console.log(`${config.baseURL}${config.url}`)
    if (!isServer) {
      if (cookie.getToken()) {
        config.headers['x-access-token'] = cookie.getToken();
      }
      // const geoInfo = getGeo();
      // if (geoInfo) {
      //   config.headers['user-geo'] = geoInfo.userGeo;
      //   config.headers['user-location'] = encodeURI(geoInfo.userLocation);
      //   config.headers['user-identity'] = geoInfo.userIdentity;
      // }
    }
    return config;
  },
  (error) => {
    if (!isServer) {
      Spin.hide();
    }
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  (res) => {
    // console.log(res)
    const { data } = res;
    if (data.code !== 1) {
      if (!isServer) {
        if (data.code === 1003) {
          // 清除cookie中的token和localStorage中的用户信息
          $nuxt.$store.commit('user/clearUserInfo');
          // 显示登录弹窗
          const showAccountModal = $nuxt.$store.state.user.showAccountModal;
          if (!showAccountModal) {
            const params = {
              isShow: true,
              showType: ACCOUNT_SHOW_TYPE.LOGIN_PASS.value
            };
            $nuxt.$store.commit('user/changeAccountModal', params);
            Message.error('未登录或登录失效，请登录');
          }
        } else {
          Message.error(data.msg);
          Spin.hide();
        }
      }
      console.error(data);
      return Promise.reject(res);
    }
    return data;
  },
  (error) => {
    // 对响应错误做点什么
    if (!isServer) {
      Spin.hide();
      Message.error('服务内部错误');
    }
    return Promise.reject(error);
  }
);

export const postAxios = (url, data) => {
  return axios.post(url, data);
};
export const getAxios = (url, data) => {
  return axios.get(url, {
    params: data
  });
};
