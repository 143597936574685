<!--
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-21 17:31:57
 * @LastEditors: hanyu
 * @LastEditTime: 2021-03-25 11:01:02
-->
<template>
  <div class="footer-box">
    <div class="footer-company">
      <div class="company-info">
        <img class="company-icon" :src="companyInfo.companyLogo" />
        <div>
          <div class="company-name">
            <span>{{ companyInfo.siteName }}</span>
            <img class="progress-icon" src="@/assets/image/index/progress-icon.png" />
          </div>
          <div>
            制作课程的每一个环节，我们力求精益求精
          </div>
        </div>
      </div>
      <div v-if="androidUrl || iosUrl" class="app-info">
        <div v-if="androidUrl" class="app-code" style="margin-right: 36px">
          <img class="download-qr-code" :src="androidUrl" />
          <p class="download-discribe">
            <img class="system-icon" src="@/assets/image/index/android-icon.png" />安卓APP下载
          </p>
        </div>

        <div v-if="iosUrl" class="app-code">
          <img class="download-qr-code" :src="iosUrl" />
          <p class="download-discribe">
            <img class="system-icon" src="@/assets/image/index/ios-icon.png" />苹果APP下载
          </p>
        </div>
      </div>
      <div class="footer-icon">
        <img src="@/assets/image/index-footer-icon.png" />
      </div>
    </div>
    <div class="footer-copy" v-if="companyInfo.beiAnNo">
      <div class="footer-content">
        <div class="copy-content">
          <div>
            备案号：<a href="https://beian.miit.gov.cn" target="_blank">{{ companyInfo.beiAnNo }}</a> 版权所有©
            2006{{ copyYear }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authe1 from '@/assets/image/index/authe-1.jpg';
import authe2 from '@/assets/image/index/authe-2.jpg';
import authe3 from '@/assets/image/index/authe-3.jpg';
import authe4 from '@/assets/image/index/authe-4.jpg';
import authe5 from '@/assets/image/index/authe-5.jpg';
import authe6 from '@/assets/image/index/authe-6.jpg';
import ThirdCodeModal from './components/third-modal';

export default {
  name: 'IndexFooter',
  components: {
    ThirdCodeModal
  },
  filters: {},
  props: {},
  data () {
    return {
      autheList: [authe1, authe2, authe3, authe4, authe5, authe6],
      copyYear: `-${new Date().getFullYear()}`
    };
  },
  computed: {
    companyInfo () {
      return this.$store.state.companyInfo || {};
    },
    androidUrl () {
      const androidImg = this.companyInfo.androidImg || '';
      return androidImg;
    },
    iosUrl () {
      const iosImg = this.companyInfo.iosImg || '';
      return iosImg;
    }
  },
  watch: {},
  created () {
  },
  mounted () {
    $('.third-item').hover(function () {
      $(this).children('div').stop(true).fadeIn();
    }, function () {
      $(this).children('div').stop(true).fadeOut();
    });
  },
  methods: {

    // 点击打开链接
    openLink (link) {
      const tempWindow = window.open('_blank');
      tempWindow.location = link;
    }
  }
};
</script>
<style lang='less' scoped>
.footer-box {
  background-color: #333;
}

.footer-content {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.footer-company {
  position: relative;
  width: 1200px;
  height: 200px;
  display: flex;
  justify-content: space-between;
  margin: auto;

  .footer-icon {
    width: 366px;

    > img {
      position: absolute;
      top: -56px;
      right: 12px;
      width: 366px;
      height: 256px;
    }

  }

  .company-info {
    display: flex;
    margin-top: 64px;
    margin-bottom: 64px;
    color: #ffffff;

    .company-icon {
      width: 72px;
      height: 72px;
      margin-right: 20px;
    }

    .company-name {
      height: 45px;
      font-size: 32px;
      font-weight: 800;
      line-height: 29px;
      letter-spacing: 2px;
      margin-bottom: 4px;
    }

    .progress-icon {
      width: 54px;
      height: 14px;
    }
  }

  .app-info {
    margin-top: 24px;
    height: 152px;
    opacity: 1;
    background: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 25px;

    .app-code {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 19px;

      .download-qr-code {
        width: 100px;
        height: 100px;
      }

      .download-discribe {
        display: flex;
        margin-top: 8px;
        height: 18px;
        line-height: 18px;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #333333;
      }

      .system-icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.footer-copy {
  height: 47px;
  background-color: #1a1a1a;

  .footer-content {
    width: 1200px;
    margin: 0 auto;
    height: 47px;
    display: flex;
    align-items: center;

    .footer-authe {
      height: 47px;
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 56px;
        height: 16px;
        margin-right: 7px;
      }
    }

    .copy-content {
      flex: 1;
      text-align: center;
      font-size: 12px;
      line-height: 20px;
      color: #666;

      a {
        color: #666;

        &:hover {
          color: @main-color;
        }
      }
    }
  }
}
</style>
