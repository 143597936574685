/*
 * @Description: 全局过滤器
 * @Author: hanyu
 * @Date: 2020-03-12 17:13:42
 * @LastEditTime: 2020-12-14 13:53:54
 * @LastEditors: 李云飞
 */
import Vue from 'vue';
import { Decimal } from 'decimal.js';

//  金额格式化，保留2位小数
export const moneyFormat = (value) => {
  const y = new Decimal(value);
  return y.toFixed(2, Decimal.ROUND_DOWN);
};

export default function () {
  // 所有过滤器方法在这里注册
  Vue.filter('moneyFormat', moneyFormat);
  //
  Vue.prototype.$moneyFormat = moneyFormat;
}
