/*
 * @Description: 图书商城
 * @version:
 * @Author: 李云飞
 * @Date: 2020-12-11 09:27:18
 * @LastEditors: hanyu
 * @LastEditTime: 2020-12-17 08:57:28
 */

export const GOODS_MALL_USER_TYPE_ENUM = {
  USER: {
    value: 1,
    desc: '用户'
  },
  SCHOOL: {
    value: 2,
    desc: '分校'
  }
};

// 图书销售类型
export const GOODS_SALES_TYPE_ENUM = {
  PRE_SALE: {
    value: 1,
    desc: '预售'
  },
  SPORT: {
    value: 2,
    desc: '现货'
  }
};

export const RECEIVE_ADDRESS_MODULE_TYPE_ENUM = {
  USER_ADDRESS: {
    value: 1,
    desc: '学员收货地址'
  },
  SCHOOL_ADDRESS: {
    value: 2,
    desc: '分校收货地址'
  }
};
export const
  MALL_ORDER_TRANSPORT_TYPE_ENUM =
{
  LOGISTICS: {
    value: 1,
    desc: '物流'
  },
  SF: {
    value: 201,
    desc: '顺丰'
  },
  STO: {
    value: 202,
    desc: '申通'
  },
  ZTO: {
    value: 203,
    desc: '中通'
  },
  YTO: {
    value: 204,
    desc: '圆通'
  },
  EMS: {
    value: 205,
    desc: 'EMS'
  },
  CHINA_POST: {
    value: 206,
    desc: '邮政包裹'
  },
  JD: {
    value: 207,
    desc: '京东'
  },
  YUNDA: {
    value: 208,
    desc: '韵达'
  },
  HTKY: {
    value: 209,
    desc: '百世快递'
  }
};
export default {
  GOODS_SALES_TYPE_ENUM,
  RECEIVE_ADDRESS_MODULE_TYPE_ENUM,
  GOODS_MALL_USER_TYPE_ENUM,
  MALL_ORDER_TRANSPORT_TYPE_ENUM
};
