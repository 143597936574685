<!--
 * @Description:  浮动客服
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-25 20:21:25
 * @LastEditors: 李云飞
 * @LastEditTime: 2020-09-27 15:08:50
-->
<template>
  <div
    id="onlineService"
    class="online-service"
  >
    <div class="service-menu">
      <div class="menu-item customer">
        咨询
        <div class="menu-item-content">
          <div class="code-img">
            <img :src="guideWxImg">
            <p>微信扫一扫，添加客服咨询</p>
          </div>
        </div>
      </div>
      <div class="menu-item app">
        App下载
        <div class="menu-item-content" style="margin-top: -156px;">
          <div class="code-img">
            <img :src="androidImg">
            <p>安卓App下载</p>
          </div>
        </div>
        <div class="menu-item-content">
          <div class="code-img">
            <img :src="iosImg">
            <p>苹果APP下载</p>
          </div>
        </div>
      </div>

      <div class="menu-item feedback" @click="openFeedback('feed-back',{hideSubTitle:1})">
        意见反馈
      </div>
    </div>
    <div class="to-top">
      <div
        class="menu-item"
        @click="toTop"
      >
        返回顶部
      </div>
    </div>
  </div>
</template>

<script>
import appCode from '@/assets/image/common/code-app.png';
import offcialCode from '@/assets/image/common/code-offcial.jpg';
import { ACCOUNT_SHOW_TYPE } from '@/constant/account';

export default {
  name: 'FloatCustomerService',
  components: {},
  filters: {},
  props: {},
  data () {
    return {
      appCode,
      offcialCode
    };
  },
  computed: {
    userInfo () {
      return this.$store.state.user.userInfo;
    },
    // 获取url
    androidImg () {
      const companyInfo = this.$store.state.companyInfo || {};
      const androidImg = companyInfo.androidImg;
      return androidImg;
    },
    // 获取url
    iosImg () {
      const companyInfo = this.$store.state.companyInfo || {};
      const iosImg = companyInfo.iosImg;
      return iosImg;
    },
    guideWxImg () {
      const companyInfo = this.$store.state.companyInfo || {};
      const guideWxImg = companyInfo.guideWxImg;
      return guideWxImg;
    }
  },
  watch: {},
  created () {
  },
  mounted () {
    window.onscroll = function () {
      const onlineService = document.getElementById('onlineService');
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const nTarget = scrollTop + 310;
      if (onlineService) {
        move(onlineService, parseInt(nTarget));
      }
    };

    let oMoveTimer = null;

    function move (oDom, nTarget) {
      clearInterval(oMoveTimer);
      oMoveTimer = setInterval(function () {
        let speed = (nTarget - oDom.offsetTop) / 6;
        speed = speed > 0 ? Math.ceil(speed) : Math.floor(speed);
        if (oDom.offsetTop === nTarget) {
          clearInterval(oMoveTimer);
          return;
        }
        oDom.style.top = oDom.offsetTop + speed + 'px';
      }, 30);
    }
  },
  methods: {
    // 在线客服
    openQimoChat () {
      window.qimoChatClick();
    },
    openFeedback (path, query) {
      if (this.lodash.isEmpty(this.userInfo)) {
        const params = {
          isShow: true,
          showType: ACCOUNT_SHOW_TYPE.LOGIN_PASS.value
        };
        this.$store.commit('user/changeAccountModal', params);
        return;
      }
      this.openPc(path, query);
    },
    // 跳转PC页面
    openPc (path, query) {
      this.$pcRouter.push({ path, query });
    },
    // 返回顶部
    toTop () {
      $('body,html').animate({ scrollTop: 0 }, 500);
    }
  }
};
</script>
<style lang='less' scoped>
  @import "./index.less";
</style>
