/*
 * @Description: 
 * @version: 
 * @Author: 李云飞
 * @Date: 2020-09-24 18:56:40
 * @LastEditors: 李云飞
 * @LastEditTime: 2020-09-24 19:10:56
 */
import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)