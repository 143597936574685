/*
  * @Description:
 * @Author: hanyu
 * @Date: 2020-05-23 11:51:03
 * @LastEditTime: 2020-09-26 11:38:05
 * @LastEditors: 李云飞
 */

import { companyApi } from '@/api/company.js';

export const state = () => ({
  companyInfo: {},
  defaultTab: 0,// 默认 顶部 tab  0 -》首页
})
export const getters = {
}
export const mutations = {
  // 设置机构信息
  SET_COMPANY_INFO (state, data) {
    state.companyInfo = data;
  },
}
export const actions = {
}
