/*
 * @Description: 课程枚举
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-24 10:13:17
 * @LastEditors: 李云飞
 * @LastEditTime: 2020-09-25 15:32:18
 */

// ----------------------------- 云平台 -------------------------------
export const
  COURSE_TYPE_ENUM =
        {
          COURSE: {
            value: 1,
            desc: '基础班'
          },
          COURSE_SERIES: {
            value: 2,
            desc: '套餐班'
          }
        };

export const
  COURSE_EXPIRY_TYPE_ENUM =
        {
          FIXED_DAY: {
            value: 1,
            desc: '固定天数'
          },
          SPECIFY_TIME: {
            value: 2,
            desc: '指定日期'
          }
        };
export const
  COURSE_CATALOG_TASK_TYPE_ENUM =
        {
          LIVE: {
            value: 1,
            desc: '直播'
          },
          VIDEO: {
            value: 2,
            desc: '视频'
          },
          MATERIAL: {
            value: 3,
            desc: '资料'
          },
          PAPER: {
            value: 4,
            desc: '试卷'
          }
        };

export const
  LIVE_CHANNEL_STATUS_ENUM =
        {
          NOT_START: {
            value: 0,
            desc: '未开始'
          },
          LIVING: {
            value: 1,
            desc: '直播中'
          },
          COMPLETE: {
            value: 2,
            desc: '已结束'
          }
        };
export const
  COURSE_CATALOG_TASK_COST_TYPE_ENUM =
        {
          NO_FREE: {
            value: 1,
            desc: '必须付费'
          },
          FREE: {
            value: 2,
            desc: '免费'
          },
          FREE_TREE_MINUTE: {
            value: 3,
            desc: '免费3分钟'
          }
        };
// ----------------------------- 云平台 -------------------------------
// 售卖类型
export const COURSE_SALE_TYPE_ENUM =
    {
      COURSE_FRANCO: {
        value: 1,
        desc: '免费课程'
      },
      COURSE_CHARGE: {
        value: 2,
        desc: '收费课程'
      },
      COURSE_RECEIVE: {
        value: 3,
        desc: '领取课程'
      }
    };

// 课程属性
export const COURSE_ATTRIBUTE_ENUM =
    {
      PLAN: {
        value: 5,
        desc: '旗舰班'
      },
      CLASS: {
        value: 3,
        desc: '精品班'
      },
      OFFLINE: {
        value: 4,
        desc: '面授班'
      },
      VIDEO: {
        value: 1,
        desc: '视频课'
      },
      LIVE: {
        value: 2,
        desc: '直播课'
      },
      COURSE: {
        value: 6,
        desc: '专项课'
      }
    };

// 直播状态
export const COURSE_LIVE_TYPE_ENUM =
    {
      NOT_START: {
        value: 0,
        desc: '未开始'
      },
      LIVING: {
        value: 1,
        desc: '直播中'
      },
      COMPLETE: {
        value: 2,
        desc: '已结束'
      }
    };

export const POWER_TYPE_ENUM =
    {
      PUBLIC: {
        value: 1,
        desc: '公开的'
      },
      USER: {
        value: 2,
        desc: '仅学员'
      }
    };

export const
  RESOURCE_ATTRIBUTE_ENUM =
        {
          VIDEO: {
            value: 1,
            desc: '视频资源'
          },
          LIVE: {
            value: 2,
            desc: '直播资源'
          }
        };

// 直播回放类型
export const LIVE_PLAYBACK_TYPE_ENUM = {
  LIVE_PLAYBACK: {
    value: 1,
    desc: '直播回放'
  },
  VIDEO: {
    value: 2,
    desc: '视频文件'
  }
};

export default {
  LIVE_CHANNEL_STATUS_ENUM,
  COURSE_TYPE_ENUM,
  COURSE_EXPIRY_TYPE_ENUM,
  COURSE_CATALOG_TASK_TYPE_ENUM,
  COURSE_CATALOG_TASK_COST_TYPE_ENUM,
  COURSE_SALE_TYPE_ENUM,
  COURSE_ATTRIBUTE_ENUM,
  COURSE_LIVE_TYPE_ENUM,
  POWER_TYPE_ENUM,
  RESOURCE_ATTRIBUTE_ENUM,
  LIVE_PLAYBACK_TYPE_ENUM
};
