/*
 * 获取地理位置 ;
 * 1. 调用腾讯地图api
 * 2. 由前端主动触发，每隔10分钟更新一次
 * 3. 数据存储到cookie里
 * @author: zhuoda
 */

import Vue from 'vue';
import vueJsonp from 'vue-jsonp'
import Axios from 'axios';
import Cookies from 'js-cookie';

Vue.use(vueJsonp)

const COOKIE_GEO_KEY = 'renminyixue_user_geo';
const TEN_MINUTES = new Date(new Date().getTime() + 10 * 60 * 1000);
const ONE_MINUTES = new Date(new Date().getTime() + 5 * 60 * 1000);

const isServer = Vue.prototype.$isServer;

const geoAxios = Axios.create({
    timeout: 30000,
    headers: {
        'Content-Type': 'jsonp'
    }
});

function generateUUID() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    });
    return uuid;
};

const updateGeo = () => {
    try {
        Vue.prototype.$jsonp('https://apis.map.qq.com/ws/location/v1/ip', {
            key: 'XGQBZ-LQEK3-A363P-YL6RR-DTO2K-E4FE2',
            output: 'jsonp'
        }).then(e => {
            if(e.status !== 0){
                Cookies.set(COOKIE_GEO_KEY, {}, {
                    expires: ONE_MINUTES
                });
                return;
            }

            const geo = {
                userGeo: e.result.location.lat + ',' + e.result.location.lng,
                userLocation: e.result.ad_info.nation
                    + '/' + e.result.ad_info.province
                    + '/' + e.result.ad_info.city
                    + '/' + e.result.ad_info.district
                    + '/' + e.result.ad_info.adcode
                ,
                userIdentity: generateUUID()
            };

            Cookies.set(COOKIE_GEO_KEY, JSON.stringify(geo), {
                expires: TEN_MINUTES
            });
        });
    } catch (e) {
        console.log(e);
    }
}

export const getGeo = () => {
    // 获取 geo
    try {
        const geoInfo = Cookies.get(COOKIE_GEO_KEY);
        if (geoInfo) {
            return JSON.parse(geoInfo);
        } else {
            setTimeout(() => {
                updateGeo();
            }, 500);
            return {
                userGeo: '',
                userLocation: '',
                userIdentity: ''
            };
        }
    } catch (e) {
        return {};
    }
}


