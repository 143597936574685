/*
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-24 10:03:32
 * @LastEditors: hanyu
 * @LastEditTime: 2021-05-18 11:32:30
 */
import { postAxios, getAxios } from '@/lib/http';

export const courseApi = {
  // ------------------------  云平台 ---------------------------
  // 分页查询课程列表 by listen
  queryList (data) {
    return postAxios('/app/course/query', data);
  },
  // 查询课程详情 by listen
  getCourseDetail (courseId) {
    return getAxios(`/app/course/detail/${courseId}`);
  },
  // ------------------------  云平台 ---------------------------

  // PC首页-免费课程 by liyingwu
  queryFreeList (data) {
    const majorId = data.majorId;
    const showNum = data.showNum;
    return getAxios(`/pc/index/course/new/free/${majorId}/${showNum}`);
  },

  // PC首页-精品直播列表 by liyingwu
  queryIndexLive (data) {
    return postAxios('/app/index/new/live/resource', data);
  },

  // PC前端获取直播/回放url by lianmin
  queryLivePlayUrl (data) {
    return postAxios('/app/course/live/detail/query', data);
  },

  // 视频课 - 课程详情 by liyingwu
  getCourseVideoDetail (courseId) {
    return getAxios(`/pc/course/video/new/detail/${courseId}`);
  },
  // 直播课 - 课程详情 by liyingwu
  getCourseLiveDetail (courseId) {
    return getAxios(`/pc/course/live/new/detail/${courseId}`);
  },
  // 精品班 - 课程详情 by liyingwu
  getCourseClassDetail (courseId) {
    return getAxios(`/pc/course/class/new/detail/${courseId}`);
  },
  // 旗舰班 - 课程详情 by liyingwu
  getCoursePlanDetail (courseId) {
    return getAxios(`/pc/course/plan/new/detail/${courseId}`);
  },
  // 面授班 - 课程详情 by liyingwu
  getCourseOfflineDetail (courseId) {
    return getAxios(`/pc/course/offline/new/detail/${courseId}`);
  },
  // 学习 - 直播专项课 - 视频观看页（老系统看回放） by liyingwu
  getOldLiveDetail (data) {
    return postAxios('/pc/user/course/oldLive', data);
  },
  // PC前端获取直播/回放url by lianmin
  getLivePlayUrl (data) {
    return postAxios('/pc/course/live/play/url', data);
  },

  // 查询直播资源详情 (学习 - 直播专项课 - 观看页 by liyingwu)
  queryLiveResourceDetail (data) {
    // return getAxios('/app/user/course/query');
    return postAxios('/app/live/channel/verify', data)
  },

  // 【新】查询学员最后一次学习位置 by liyingwu
  queryLastCourseRecord () {
    return getAxios('/pc/index/course/record/last/query');
  },

  // 推荐课程【新】 by liyingwu
  queryRecommend (data) {
    return postAxios('/pc/course/recommend/new/query', data);
  }
};
