<!--
 * @Description: 验证码登录
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-26 08:44:38
 * @LastEditors: 康蔚明
 * @LastEditTime: 2020-09-30 09:47:14
-->
<template>
    <div>
        <div class="title">
            验证码登录/注册
        </div>
        <div class="change-login-type">
            <a href="javascript:;" @click="changeLoginType">密码登录</a>
        </div>
        <div class="form">
            <div class="form-row phone">
                <div class="input-conent">
                    <Input v-model.trim="formData.phone" placeholder="请输入手机号码" maxlength="11" />
                </div>
            </div>
            <div class="form-row phone">
                <div class="input-conent">
                    <Input v-model.trim="captchaCode" placeholder="请输入图形验证码" maxlength="6" style="width:150px" />
                </div>
                <div class="verify-wrap">
                    <img class="verify-img" :src="smsVerify" @click="getVerifyImg" />
                    <span @click="getVerifyImg">看不清，换一张</span>
                </div>
            </div>
            <div class="form-row sms">
                <div class="input-conent">
                    <Input v-model.trim="formData.smsCode" placeholder="请输入验证码" maxlength="6" />
                </div>
                <div class="get-sms" :class="{disable: disableGetBut}" @click="sendVerify">
                    {{ getSmsTips }}
                </div>
            </div>
            <div class="send-button" @click="loginValidate">
                登录/注册
            </div>
        </div>
        <Spin v-if="isLoading" fix>
            <Icon type="ios-loading" size="36" class="demo-spin-icon-load" />
        </Spin>
    </div>
</template>

<script>
import { accountApi } from '@/api/account';
import { commonApi } from '@/api/common';
import { regular } from '@/lib/regular';
import { SMS_VERIFY_ACTION_TYPE_ENUM, SMS_VERIFY_TYPE_ENUM } from '@/constant/common';
import { ACCOUNT_SHOW_TYPE, USER_TERMINAL_APP_TYPE_ENUM, USER_DEVICE_TYPE_ENUM } from '@/constant/account';
import { getSystemName, getBrowserName } from '@/lib/utils';

export default {
  name: 'AccountLoginSms',
  components: {},
  filters: {},
  props: {},
  data() {
    return {
      smsVerify: null, // 图形验证码base64图片
      verifyId: null, // 图形验证码UUID
      captchaCode: null, // 图形验证码
      SMS_VERIFY_TYPE_ENUM,
      formData: {
        phone: null,
        smsCode: null
      },
      isLoading: false,
      getSmsTips: '获取验证码',
      disableGetBut: false,
      countDownTimer: null // 定时器
    };
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
    this.getVerifyImg();
  },
  beforeDestroy() {
    this.resetParams();
    this.unListenEnterKeyDown();
  },
  methods: {

    // 监听回车按下事件
    listenEnterKeyDown() {
      document.onkeydown = (e) => {
        const event = e || event;
        if (event.keyCode == 13) {
          event.preventDefault();
          this.loginValidate();
        }
      };
    },
    // 撤销监听回车按下事件
    unListenEnterKeyDown() {
      document.onkeydown = undefined;
    },
    // 切换到密码登录
    changeLoginType() {
      this.$store.commit('user/changeAccountType', ACCOUNT_SHOW_TYPE.LOGIN_PASS.value);
    },

    // 发送验证码
    async sendVerify() {
      if (this.disableGetBut) {
        return;
      }
      try {
        this.isLoading = true;
        if (!this.formData.phone) {
          this.$Message.error('请输入手机号码');
          return;
        }
        if (!this.captchaCode) {
          this.$Message.error('请输入图形验证码');
          return;
        }
        if (!regular.phone.test(this.formData.phone)) {
          this.$Message.error('手机号码不正确');
          return;
        }
        await commonApi.sendVerify(this.formData.phone, SMS_VERIFY_TYPE_ENUM.VERIFICATION_CODE_LOGIN.value, this.verifyId, this.captchaCode);
        this.runCountDown();
        this.$Message.success('验证码已发送');
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },
    // 发送验证码
    async getVerifyImg() {
      try {
        this.isLoading = true;
        const { data: result } = await accountApi.getSmsVerify();
        this.smsVerify = result.code;
        this.verifyId = result.uuid;
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },
    // 开始倒计时
    runCountDown() {
      this.disableGetBut = true;
      let countDown = 60;
      this.getSmsTips = `${countDown}秒重新获取`;
      this.countDownTimer = setInterval(() => {
        if (countDown > 1) {
          countDown--;
          this.getSmsTips = `${countDown}秒重新获取`;
        } else {
          clearInterval(this.countDownTimer);
          this.disableGetBut = false;
          this.getSmsTips = '获取验证码';
        }
      }, 1000);
    },

    // 点击登录，验证登录信息
    loginValidate() {
      if (!this.formData.phone) {
        this.$Message.error('请输入手机号码');
        return;
      }
      if (!regular.phone.test(this.formData.phone)) {
        this.$Message.error('手机号码不正确');
        return;
      }
      if (!this.formData.smsCode) {
        this.$Message.error('请输入验证码');
        return;
      }
      this.loginBuySms();
    },

    // 登录api
    async loginBuySms() {
      try {
        this.isLoading = true;
        // 设备信息
        const systemName = getSystemName();
        // 浏览器名称
        const browserName = getBrowserName();
        const param = Object.assign(this.formData,
          {
            deviceType: USER_DEVICE_TYPE_ENUM.PC.value,
            deviceInfo: `${systemName}${browserName}`,
            terminalAppType: USER_TERMINAL_APP_TYPE_ENUM.PC_ZHI_KAO_DA_XUE.value
          });
        const result = await accountApi.loginBuySms(param);
        this.$emit('loginSuccess', result.data);
        this.$Message.success('登录成功');
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },

    // 重置数据
    resetParams() {
      clearInterval(this.countDownTimer);
      Object.assign(this.$data, this.$options.data.call(this));
    }

  }
};
</script>
<style lang='less' scoped>
@import "./account.less";
.verify-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  .verify-img {
    height: 30px;
  }
  span {
    font-size: 8px;
  }
}
</style>
