/*
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-24 10:13:17
 * @LastEditors: 李云飞
 * @LastEditTime: 2020-09-26 16:08:30
 */

//  搜索类型
export const SEARCH_TYPE_ENUM = {
  COURSE: {
    value: 1,
    desc: '课程'
  },
  // LECTURER: {
  //   value: 2,
  //   desc: '师资'
  // },
  // NEWS: {
  //   value: 3,
  //   desc: '资讯'
  // },
  BOOK: {
    value: 4,
    desc: '商城'
  }
};

// 轮播图位置
export const PIC_LOCATION_ENUM = {
  HOME: {
    value: 1,
    desc: '首页轮播图'
  },
  QUESTION: {
    value: 2,
    desc: '题库轮播图'
  },
  PC_HOME: {
    value: 3,
    desc: 'PC首页轮播图'
  }
};

export const PIC_GOTO_TYPE_ENUM =
  {
    URL: {
      value: 1,
      desc: '超链接跳转'
    },
    COURSE: {
      value: 2,
      desc: '课程'
    },
    GOODS: {
      value: 3,
      desc: '商品'
    }
  };
// 轮播图类型
export const PIC_TYPE_ENUM = {
  VIDEO: {
    value: 1,
    desc: '视频课'
  },
  LIVE: {
    value: 2,
    desc: '直播课'
  },
  CLAZZ: {
    value: 3,
    desc: '精品班'
  },
  FACE: {
    value: 4,
    desc: '面授班'
  },
  PLAN: {
    value: 5,
    desc: '课程体系'
  },
  ONLY_SHOW: {
    value: 6,
    desc: '仅展示'
  },
  URL: {
    value: 7,
    desc: '网址'
  },
  EXAMINATION: {
    value: 8,
    desc: '试卷'
  },
  LIBRARY: {
    value: 9,
    desc: '精品题库'
  },
  GOODS: {
    value: 10,
    desc: '图书商品'
  },
  QUESTION_HOME: {
    value: 11,
    desc: '题库首页'
  }
};

// 获取验证码事件类型 无用
export const SMS_VERIFY_ACTION_TYPE_ENUM =
  {
    NONE: {
      value: 0,
      desc: '无'
    },
    BIND_THIRD_ACCOUNT: {
      value: 1,
      desc: '绑定第三方账户'
    },
    SMS_VERIFY_LOGIN: {
      value: 2,
      desc: '短信验证码登录'
    },
    SMS_VERIFY_RESET_PWD: {
      value: 3,
      desc: '修改密码'
    },
    SMS_VERIFY_REGISTER: {
      value: 4,
      desc: '账号注册'
    },
    UPDATE_PHONE: {
      value: 5,
      desc: '更换手机号'
    }
  };

// 获取验证码类型
export const SMS_VERIFY_TYPE_ENUM =
  {
    REGISTER: {
      value: 5,
      desc: '账号注册'
    },
    UPDATE_PWD: {
      value: 10,
      desc: '修改密码'
    },
    AUTHENTICATION: {
      value: 15,
      desc: '身份验证'
    },
    VERIFICATION_CODE_LOGIN: {
      value: 20,
      desc: '验证码登录'
    }
  };

export default {
  SEARCH_TYPE_ENUM,
  PIC_LOCATION_ENUM,
  PIC_TYPE_ENUM,
  SMS_VERIFY_ACTION_TYPE_ENUM,
  SMS_VERIFY_TYPE_ENUM,
  PIC_GOTO_TYPE_ENUM
};
