/*
 * @Description: 
 * @version: 
 * @Author: 李云飞
 * @Date: 2020-05-18 16:03:53
 * @LastEditors: 李云飞
 * @LastEditTime: 2020-05-18 16:15:23
 */

// 表格分页相关配置

/**
 * 每页条数
 */
export const PAGE_SIZE = 10;
/**
 * 每页条数切换的配置
 */
export const PAGE_SIZE_OPTIONS = [10, 20, 30, 50, 100];

export default {
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS
}
