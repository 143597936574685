/*
 * @Description: 正则表达式
 * @Author: hanyu
 * @Date: 2020-03-09 10:27:16
 * @LastEditTime: 2020-04-24 15:17:28
 * @LastEditors: 康蔚明
 */
export const regular = {
  phone: /^(13|14|15|16|17|18|19)\d{9}$/,
  pass: /^[A-Za-z0-9._]{6,20}$/,	// 6-20位字母数字下划线
  qq: /^[1-9]\d{3,}$/,
  linkUrl: /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/,
  isNumber: /(^[\-1-9][1-9]*(.[1-9]+)?)$/, // 判断是否为数字，除了0 外
  isIdentityCard: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/ // 验证身份证号 
};
