<!--
 * @Description:
 * @Author: hanyu
 * @Date: 2020-12-23 20:39:30
 * @LastEditTime: 2021-01-07 09:04:53
 * @LastEditors: hanyu
-->
<!--
 * @Description: 资讯和帮助中心
 * @Author: zhuoda
-->
<template>
  <div class="root">
    <!-- 首页、全部课程 等 、个人中心 -->
    <MainHeader />
    <!-- 浮动客服 -->
    <FloatCustomerService ref="floatCustomerService" />
    <!-- 登录弹窗 -->
    <Account ref="accountRef" />
    <nuxt keep-alive :keep-alive-props="{ include: includeArr }" />
    <IndexFooter />
  </div>
</template>

<script>
import MainHeader from '@/components/header/main-header';
import Account from '@/components/account';
import FloatCustomerService from '@/components/float-customer-service';
import IndexFooter from '@/components/index-footer';

export default {
  name: 'NewsLayout',
  components: {
    MainHeader, Account, FloatCustomerService, IndexFooter
  },
  filters: {},
  props: {},
  data () {
    return {
      includeArr: ['Teacher']
    };
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
  },
  beforeCreate () {
  },
  beforeMount () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  activated () {
  },
  methods: {
  },
  head () {
    return {
      // link: [
      //   { rel: 'stylesheet', href: 'https://cdn.bootcss.com/twitter-bootstrap/3.3.7/css/bootstrap.min.css' }
      // ]
    };
  }
};
</script>
<style lang='less' scoped>
</style>
