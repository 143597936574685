/*
 * @Description: 
 * @version: 
 * @Author: 李云飞
 * @Date: 2020-08-25 15:31:54
 * @LastEditors: 李云飞
 * @LastEditTime: 2020-08-26 10:06:48
 */

// 存储
export const localSave = (key, value) => {
  localStorage.setItem(key, value);
};

// 读取
export const localRead = key => {
  return localStorage.getItem(key) || '';
};

// 删除
export const localRemove = key => {
  localStorage.removeItem(key);
};
