/*
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-08-25 17:25:47
 * @LastEditors: hanyu
 * @LastEditTime: 2021-05-18 11:29:59
 */
import { postAxios, getAxios } from '@/lib/http';
// import Cookies from '@/lib/cookie';
export const commonApi = {
  // 课程专业 by lihaifan
  getMajorList () {
    return getAxios('/pc/resource/major/list');
  },

  // 查询展示图 by lianmin
  queryBanner (data) {
    return postAxios('/app/pic/query', data);
  },

  // 发送短信验证码
  sendVerify (phone, smsType, uuId, captchaCode) {
    return getAxios(`/app/sms/verify/send/${phone}/${smsType}?uuId=${uuId}&captchaCode=${captchaCode}`);
  },
  // 更新用户专业 by liste
  updateMajor (data) {
    return postAxios('/pc/user/major/update', data);
  },
  // 根据IP查询推荐引流分校 - 分校 by lianmin
  getSchoolPcDrainage (data) {
    return postAxios('/pc/resource/school/PC/drainage', data);
  },

  // 更新用户分校信息 by liyingwu
  updateSchoolId (schoolId) {
    return getAxios(`/pc/user/update/school/${schoolId}`);
  },

  // pc是否在所选区域内 by lihaifan
  pcUserInRegionalRestrictions () {
    return getAxios('/pc/user/pcUserInRegionalRestrictions');
  },
  // 查询用户是否完善信息 by listen
  checkUserProfilePerfect () {
    return getAxios('pc/user/profile/perfect/new');
  }
};
