<!--
 * @Description: 忘记密码
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-26 08:44:38
 * @LastEditors: 康蔚明
 * @LastEditTime: 2020-09-30 09:44:19
-->
<template>
  <div>
    <div class="title">
      忘记密码
    </div>
    <div class="change-login-type">
      <a
        href="javascript:;"
        @click="changeLoginType"
      >返回登录</a>
    </div>
    <div class="form">
      <div class="form-row phone">
        <div class="input-conent">
          <Input
            v-model.trim="formData.phone"
            maxlength="11"
            placeholder="请输入手机号"
          />
          </Input>
        </div>
      </div>
      <div class="form-row phone">
        <div class="input-conent">
          <Input v-model.trim="captchaCode" placeholder="请输入图形验证码" maxlength="6" style="width:150px" />
        </div>
        <div class="verify-wrap">
          <img class="verify-img" :src="smsVerify" @click="getVerifyImg" />
          <span @click="getVerifyImg">看不清，换一张</span>
        </div>
      </div>
      <div class="form-row sms">
        <div class="input-conent">
          <Input
            v-model.trim="formData.smsCode"
            placeholder="请输入验证码"
            maxlength="6"
          />
          </Input>
        </div>
        <div
          class="get-sms"
          :class="{disable: disableGetBut}"
          @click="sendVerify"
        >
          {{ getSmsTips }}
        </div>
      </div>
      <div class="form-row pass">
        <div class="input-conent">
          <Input
            v-model.trim="formData.password"
            :type="passHide ? 'password' : 'text'"
            placeholder="请输入密码"
          />
          </Input>
        </div>
        <div
          class="toggle-pass"
          :class="{show: !passHide}"
          @click="passHide = !passHide"
        />
      </div>
      <div class="form-row pass">
        <div class="input-conent">
          <Input
            v-model.trim="formData.repeatPass"
            :type="repeatPassHide ? 'password' : 'text'"
            placeholder="请再次输入密码"
          />
          </Input>
        </div>
        <div
          class="toggle-pass"
          :class="{show: !repeatPassHide}"
          @click="repeatPassHide = !repeatPassHide"
        />
      </div>
      <div
        class="send-button"
        @click="resetValidate"
      >
        重置密码
      </div>
    </div>
    <Spin
      v-if="isLoading"
      fix
    >
      <Icon
        type="ios-loading"
        size="36"
        class="demo-spin-icon-load"
      />
    </Spin>
  </div>
</template>

<script>
import { accountApi } from '@/api/account';
import { commonApi } from '@/api/common';
import { regular } from '@/lib/regular';
import { SMS_VERIFY_ACTION_TYPE_ENUM, SMS_VERIFY_TYPE_ENUM } from '@/constant/common';
import { ACCOUNT_SHOW_TYPE } from '@/constant/account';

export default {
  name: 'ForgetPass',
  components: {},
  filters: {},
  props: {},
  data () {
    return {
      smsVerify: null, // 图形验证码base64图片
      verifyId: null, // 图形验证码UUID
      captchaCode: null, // 图形验证码
      formData: {
        phone: null,
        smsCode: null,
        password: null,
        repeatPass: null
      },
      passHide: true,
      repeatPassHide: true,
      isLoading: false,
      getSmsTips: '获取验证码',
      disableGetBut: false,
      countDownTimer: null // 定时器
    };
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    this.getVerifyImg()
  },
  beforeDestroy () {
    this.unListenEnterKeyDown();
  },
  methods: {
    // 监听回车按下事件
    listenEnterKeyDown () {
      document.onkeydown = (e) => {
        const event = e || event;
        if (event.keyCode == 13) {
          event.preventDefault();
          this.resetValidate();
        }
      };
    },
    // 撤销监听回车按下事件
    unListenEnterKeyDown () {
      document.onkeydown = undefined;
    },
    // 获取图像验证码
    async getVerifyImg() {
      try {
        this.isLoading = true;
        const { data: result } = await accountApi.getSmsVerify();
        this.smsVerify = result.code;
        this.verifyId = result.uuid;
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },
    // 发送验证码
    async sendVerify () {
      if (this.disableGetBut) {
        return;
      }
      try {
        this.isLoading = true;
        if (!this.formData.phone) {
          this.$Message.error('请输入手机号码');
          return;
        }
        if (!regular.phone.test(this.formData.phone)) {
          this.$Message.error('手机号码不正确');
          return;
        }
        if (!this.captchaCode) {
          this.$Message.error('请输入图形验证码');
          return;
        }
        await commonApi.sendVerify(this.formData.phone, SMS_VERIFY_TYPE_ENUM.AUTHENTICATION.value, this.verifyId, this.captchaCode);
        this.runCountDown();
        this.$Message.success('验证码已发送');
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },

    // 开始倒计时
    runCountDown () {
      this.disableGetBut = true;
      let countDown = 60;
      this.getSmsTips = `${countDown}秒重新获取`;
      this.countDownTimer = setInterval(() => {
        if (countDown > 1) {
          countDown--;
          this.getSmsTips = `${countDown}秒重新获取`;
        } else {
          clearInterval(this.countDownTimer);
          this.disableGetBut = false;
          this.getSmsTips = '获取验证码';
        }
      }, 1000);
    },

    resetValidate () {
      if (!this.formData.phone) {
        this.$Message.error('请输入手机号码');
        return;
      }
      if (!regular.phone.test(this.formData.phone)) {
        this.$Message.error('手机号码不正确');
        return;
      }
      if (!this.formData.smsCode) {
        this.$Message.error('请输入验证码');
        return;
      }
      if (!this.formData.password) {
        this.$Message.error('请输入密码');
        return;
      }
      if (!regular.pass.test(this.formData.password)) {
        this.$Message.error('请输入6-20位字母、数字或下划线的密码');
        return;
      }
      if (!this.formData.repeatPass) {
        this.$Message.error('请再次输入密码');
        return;
      }
      if (this.formData.repeatPass !== this.formData.password) {
        this.$Message.error('两次输入的密码不一致');
        return;
      }
      this.resetPassword();
    },

    // 点击重置
    async resetPassword () {
      try {
        this.isLoading = true;
        const result = await accountApi.resetPassword(this.formData);
        console.log(result);
        this.$Message.success('重置成功，请登录');
        this.changeLoginType();
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },

    // 切换到登录
    changeLoginType () {
      this.$store.commit('user/changeAccountType', ACCOUNT_SHOW_TYPE.LOGIN_PASS.value);
    },
    // 重置参数
    resetParams () {
      clearInterval(this.countDownTimer);
      Object.assign(this.$data, this.$options.data.call(this));
    }

  }
};
</script>
<style lang='less' scoped>
@import "./account.less";

.forget-pass {
  margin-top: 15px;
  text-align: right;

  a {
    color: #999;
  }
}
.verify-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  .verify-img {
    height: 30px;
  }
  span {
    font-size: 8px;
  }
}
</style>
