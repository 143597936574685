<template>
  <div :class="['new-year-header', {close: isClose}]">
    <img
        src="~@/assets/image/index/new-year-header-close.png"
        class="close"
        v-if="!isClose"
        @click.stop="isClose = true"
    />
  </div>
</template>

<script>

export default {
  data() {
    //这里存放数据
    return {
      isClose: true
    };
  },
  mounted() {
    // 只允许首页展示header
    if (this.$route.name === 'index') {
      this.isClose = false;
    }
  },
  //方法集合
  methods: {}
}
</script>

<style lang='less' scoped>
.new-year-header {
  position: relative;
  width: 100%;
  height: 50px;
  background: url('~@/assets/image/index/new-year-header.jpg') center no-repeat;
  background-size: cover;
  transition: all 0.5s;
  cursor: pointer;

  .close {
    position: absolute;
    right: 24px;
    top: 14px;
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  &.close {
    height: 0;
  }
}
</style>