/*
 * @Description:
 * @Author: hanyu
 * @Date: 2020-12-31 10:56:31
 * @LastEditTime: 2020-12-31 10:57:18
 * @LastEditors: hanyu
 */

// 推广员类型
export const DISTRIBUTION_USER_TYPE_ENUM =
{
  USER: {
    value: 10,
    desc: '学员/用户'
  },
  SCHOOL_EMPLOYEE: {
    value: 15,
    desc: '分校员工'
  }
};
export default {
  DISTRIBUTION_USER_TYPE_ENUM
};
