/*
 * @Description: seo 相关
 * @Author: hanyu
 * @Date: 2021-03-02 14:53:17
 * @LastEditTime: 2021-03-02 16:09:09
 * @LastEditors: hanyu
 */
import {
  companyApi
} from '@/api/company.js';

export const state = () => ({
  headerDetail: null
});
export const actions = {
  // 获取某个seo
  async getSeoDetail ({}, pageId) {
    try {
      const {
        data
      } = await companyApi.getCompanyInfo();
      if (!data) { return; }
      return {
        title: data.siteName,
        meta: [{
          hid: 'description',
          name: 'description',
          content: data.intro
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: data.intro
        }
        ]
      };
    } catch (e) {
      console.log(e);
    }
  }
};
