import Vue from 'vue';
import lodash from 'lodash';
import { getPcUrl } from '@/constant/pc-url';

const DEV = process.env.NUXT_ENV_MODE === 'dev' || process.env.NUXT_ENV_MODE === 'local';
/**
 * 路径
 */
const ORIGIN = DEV ? 'https://zrjy.cloud.renminyixue.cn' : window.location.origin;

/**
 * 个人中心路由跳转
 */
const personalCenterRouter = {
  install (Vue) {
    const PERSONAL_CENTER_ROUTER = {};

    PERSONAL_CENTER_ROUTER.push = ({
      path, // 路径
      query, // 参数
      redirect // 重定向
    }) => {
      const pcUrlPath = '/learn-center/#/';
      // 转换URL
      const pcUrlParams = getPcUrl(path);
      // 若local为true 则跳转到本地页面
      if (pcUrlParams.local) {
        $nuxt.$router.push({ path: pcUrlParams.url, query });
        return;
      }
      // 拼接URL
      let url = `${ORIGIN}${pcUrlPath}${pcUrlParams.url}`;
      // 拼接参数
      if (!lodash.isEmpty(query)) {
        url += '?';
        const queryList = [];
        for (const key of Object.keys(query)) {
          queryList.push(`${key}=${query[key]}`);
        }
        url += queryList.join('&');
      }
      if (redirect) {
        $nuxt.context.redirect(url);
        return;
      }
      // const newWindow = window.open(); // 先打开页面
      // newWindow.location = url; // 后更改页面地址
      window.location.href = url;
    };
    Vue.prototype.$pcRouter = PERSONAL_CENTER_ROUTER;
  }
};

Vue.use(personalCenterRouter);
