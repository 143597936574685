/*
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-08-26 09:33:56
 * @LastEditors: 李云飞
 * @LastEditTime: 2020-12-11 14:16:40
 */

import cookie from '@/lib/cookie';
import { localSave, localRead, localRemove } from '@/lib/local';
import config from '@/config';
import { Spin } from 'view-design';
import { accountApi } from '@/api/account';
import { ACCOUNT_SHOW_TYPE, TERMINAL_TYPE_ENUM } from '@/constant/account';

export const state = () => ({
  userInfo: null,
  showAccountType: ACCOUNT_SHOW_TYPE.LOGIN_PASS.value, // 登录注册弹窗显示内容类型
  showAccountModal: false, // 是否显示登录注册弹窗
  showWxLoginModal: false,
  // 触发重新查询购物车商品数量
  // 每次需要重新查询购物车商品数量时，使用changeCartFrequency方法把此值加1
  // 页面顶部购物车组件监听此值变化，重新查询购物车商品数量
  cartFrequency: 0
});
export const getters = {
  userInfo (state) {
    if (state.userInfo) {
      return state.userInfo;
    } else if (process.client) {
      return localRead(config.localUserKey);
    } else {
      return null;
    }
  },
  showWxLoginModal (state) {
    return state.showWxLoginModal;
  }
};
export const mutations = {
  // 从localStorage中获取用户信息
  readUserInfo (state) {
    const userInfo = localRead(config.localUserKey);
    if (userInfo) {
      state.userInfo = JSON.parse(userInfo);
    }
  },

  // 保存用户登录信息
  saveUserInfo (state, userInfo) {
    state.userInfo = userInfo;
    cookie.setToken(userInfo.token);
    localSave(config.localUserKey, JSON.stringify(userInfo));
  },

  // 清除用户登录信息
  clearUserInfo (state) {
    state.userInfo = null;
    cookie.clearToken();
    localRemove(config.localUserKey);
  },

  // 切换登录注册弹窗显示状态
  // isShow:是否显示；showType:显示内容ACCOUNT_SHOW_TYPE，默认是密码登录
  changeAccountModal (state, { isShow, showType = ACCOUNT_SHOW_TYPE.LOGIN_PASS.value }) {
    if (isShow) {
      state.showAccountType = showType;
    }
    state.showAccountModal = isShow;
  },

  // 切换登录注册弹窗显示内容类型
  changeAccountType (state, showType) {
    state.showAccountType = showType;
  },
  setWxModal (state, show) {
    state.showWxLoginModal = show;
  },

  // 触发重新查询购物车商品数量
  changeCartFrequency (state) {
    state.cartFrequency += 1;
  }

};
export const actions = {
  // 检查是否为微信登录，并检查是否绑定微信，未绑定，返回 false
  async checkWxLoginAndLogin ({ commit }, code) {
    // 去登录,存用户信息到本地
    try {
      Spin.show();
      const params = {
        code,
        terminalType: TERMINAL_TYPE_ENUM.PC.value
      };
      const res = await accountApi.wxUserLogin(params);
      Spin.hide();
      // 如果微信登录 并 有手机号，跳转到 首页
      if (res.code === 1 && res.data.phone) {
        commit('saveUserInfo', res.data);
        return res;
      } else {
        // 进行绑定手机号流程
        return res;
      }
    } catch (err) {
      console.log(err);
      Spin.hide();
      $nuxt.$smartSentry.captureException(err);
    }
  }
};
