/*
 * @Description: 账号相关
 * @version:
 * @Author: 李云飞
 * @Date: 2020-08-25 17:25:47
 * @LastEditors: hanyu
 * @LastEditTime: 2020-11-12 11:25:44
 */
import { postAxios, getAxios } from '@/lib/http';

export const accountApi = {
  // 账号密码登录 by listen
  loginBuyPass (data) {
    return postAxios('/app/user/login', data);
  },

  // 短信验证码登录 by listen
  loginBuySms (data) {
    return postAxios('/app/user/sms/login', data);
  },

  // 退出登录 by liyingwu
  logout () {
    return getAxios('/pc/user/logout');
  },

  // 用户注册 by liyingwu
  register (data) {
    return postAxios('/pc/user/register', data);
  },

  // 重置密码 by liyingwu
  resetPassword (data) {
    return postAxios('/app/user/pwd/reset', data);
  },
  // 查询详细资料 by listen
  getUserDetail () {
    return getAxios('/app/user/info');
  },
  // 第三方帐号登录：根据code获取微信用户信息【PC】 by listen
  getWxUserInfo (code) {
    return getAxios(`/pc/user/third/weChat/userInfo/${code}`);
  },
  //   第三方帐号登录：根据微信code登录【PC】 by listen
  wxUserLogin (data) {
    return postAxios('/pc/user/login/third/wx', data);
  },
  // 第三方帐号：注册/绑定 by listen
  userThirdRegister (data) {
    return postAxios('/pc/user/third/register', data);
  },
  // 获取【验证码】 by rjp
  getSmsVerify () {
    return getAxios('/app/sms/captcha/code');
  }
};
