/*
 * @Description: 二维码模块
 * @Author: hanyu
 * @Date: 2020-12-31 11:23:22
 * @LastEditTime: 2020-12-31 11:23:57
 * @LastEditors: hanyu
 */
import Vue from 'vue';
import vueQr from 'vue-qr';

Vue.use(vueQr);
