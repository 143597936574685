/*
 * @Description:无数据类型
 * @Author: hanyu
 * @Date: 2020-10-20 13:43:04
 * @LastEditTime: 2020-10-20 13:47:57
 * @LastEditors: hanyu
 */

// 自定义： NO_DATA_ENUM
export const NO_DATA_ENUM = {
  noData: {
    value: 0,
    desc: '通用无数据'
  },
  order: {
    value: 1,
    desc: '订单无数据'
  },
  coupon: {
    value: 2,
    desc: '优惠券无数据'
  }
};

export default {
  NO_DATA_ENUM
};
