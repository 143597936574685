<template>
  <div class="account-container">
    <div class="account-body">
      <!-- 密码登录 -->
      <LoginPass v-if="showAccountType === ACCOUNT_SHOW_TYPE.LOGIN_PASS.value" ref="loginPass" @loginSuccess="loginSuccess" />
      <!-- 验证码登录 -->
      <LoginSms v-if="showAccountType === ACCOUNT_SHOW_TYPE.LOGIN_SMS.value" ref="loginSms" @loginSuccess="loginSuccess" />
      <!-- 用户注册 -->
      <Register v-if="showAccountType === ACCOUNT_SHOW_TYPE.REGISTER.value" ref="register" @loginSuccess="loginSuccess" />
      <!-- 忘记密码 -->
      <ForgetPass v-if="showAccountType === ACCOUNT_SHOW_TYPE.FORGET_PASS.value" ref="forgetPass" />
    </div>
    <div class="lower">
      <template v-if="showAccountType === ACCOUNT_SHOW_TYPE.LOGIN_PASS.value">
        还没有账户？点击
        <a href="javascript:;" @click="changeRegister"> 立即注册 </a>
      </template>
      <template v-if="showAccountType === ACCOUNT_SHOW_TYPE.LOGIN_SMS.value">
        注册即代表同意<a href="javascript:;" @click="openAgreement('/agreement/user-agreement')">《用户协议》</a>和<a
          href="javascript:;"
          @click="openAgreement('/agreement/privacy-policy')"
          >《隐私政策》</a
        >
      </template>
    </div>
  </div>
</template>
<script>
import { ACCOUNT_SHOW_TYPE } from '@/constant/account';
import LoginSms from './login-sms';
import LoginPass from './login-pass';
import Register from './register';
import ForgetPass from './forget-pass';

export default {
  name: 'AccountContainer',
  components: {
    LoginPass,
    LoginSms,
    Register,
    ForgetPass
  },
  filters: {},
  mixins: [],
  props: {},
  data() {
    return {
      ACCOUNT_SHOW_TYPE
    };
  },
  computed: {
    showAccountType() {
      return this.$store.state.user.showAccountType;
    }
  },
  watch: {
    // 切换类型绑定事件
    showAccountType(val) {
      this.listenEnterKeyDown(val);
    }
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {
    this.unListenEnterKeyDown();
  },
  destroyed() {},
  activated() {},
  methods: {
    initListenEnterKeyDown() {
      this.unListenEnterKeyDown();
      this.listenEnterKeyDown(this.showAccountType);
    },
    // 重置数据
    resetParams() {
      switch (this.showAccountType) {
        case ACCOUNT_SHOW_TYPE.LOGIN_PASS.value:
          this.$refs.loginPass.resetParams();
          break;
        case ACCOUNT_SHOW_TYPE.LOGIN_SMS.value:
          this.$refs.loginSms.resetParams();
          break;
        case ACCOUNT_SHOW_TYPE.REGISTER.value:
          this.$refs.register.resetParams();
          break;
        case ACCOUNT_SHOW_TYPE.FORGET_PASS.value:
          this.$refs.forgetPass.resetParams();
          break;
      }
    },
    // 监听回车按下事件
    listenEnterKeyDown(showAccountType) {
      this.unListenEnterKeyDown();
      this.$nextTick(() => {
        switch (showAccountType) {
          case ACCOUNT_SHOW_TYPE.LOGIN_PASS.value:
            this.$refs.loginPass.listenEnterKeyDown();
            break;
          case ACCOUNT_SHOW_TYPE.LOGIN_SMS.value:
            this.$refs.loginSms.listenEnterKeyDown();
            break;
          case ACCOUNT_SHOW_TYPE.REGISTER.value:
            this.$refs.register.listenEnterKeyDown();
            break;
          case ACCOUNT_SHOW_TYPE.FORGET_PASS.value:
            this.$refs.forgetPass.listenEnterKeyDown();
            break;
        }
      });
    },
    // 撤销监听回车按下事件
    unListenEnterKeyDown() {
      document.onkeydown = undefined;
    },
    // 切换到注册
    changeRegister() {
      this.$store.commit('user/changeAccountType', ACCOUNT_SHOW_TYPE.LOGIN_SMS.value);
    },

    // 登录成功
    loginSuccess(userInfo) {
      if (!userInfo) {
        this.$emit('success');
        return;
      }
      userInfo.avatarUrl = userInfo.avatar;
      this.$store.commit('user/saveUserInfo', userInfo);
      this.$emit('success');
      // window.location.reload();
    },

    // 打开注册协议
    openAgreement(path) {
      this.$store.commit('user/changeAccountModal', { isShow: false });
      this.$router.push({ path });
    }
  }
};
</script>
<style lang="less" scoped>
.account-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .account-body {
    flex: 1;
    overflow: hidden;
  }

  .wechat-login {
    width: 50px;
    margin: 0 auto 50px auto;
    text-align: center;
    font-size: 12px;
    color: #999;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .wxlogo {
      width: 30px;
      height: 30px;
      margin-bottom: 6px;
    }
  }

  .lower {
    color: #999;

    a {
      color: @main-color;
    }
  }
}
</style>
