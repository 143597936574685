/*
 * @Description: 
 * @Author: hanyu
 * @Date: 2020-03-12 17:13:42
 * @LastEditTime: 2020-03-13 11:07:05
 * @LastEditors: hy
 */
import Vue from 'vue'
import Constant from './const/constant.js' // Change locale, check node_modules/iview/dist/locale
Vue.use(Constant)
