/*
 * @Description: 题库相关api
 * @Author: hzf
 * @Date: 2023-01-29 14:06:45
 */
import { postAxios, getAxios } from '@/lib/http';
export const questionApi = {
  // 查询题库详情 
  getQuestionDetail (questionGoodsId) {
    return getAxios(`/app/question/goods/detail/${questionGoodsId}`);
  },
  // 分页查询题库列表
  postQuestionList (data) {
    return postAxios('/app/question/goods/query', data)
  }
};