<!--
 * @Description: 
 * @version: 
 * @Author: 李云飞
 * @Date: 2020-09-23 15:36:34
 * @LastEditors: 李云飞
 * @LastEditTime: 2020-09-23 15:42:49
-->
<template>
  <div class="modal-wrapper">
    <div class="modal-container">
      <img :src="thirdData.qrCode">
      <p>关注官方{{thirdData.desc}}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ThirdCodeModal',
  components: {},
  props: {
    thirdData: {
      type: Object
    }
  },
  data () {
    return {
    };
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang='less' scoped>
.modal-wrapper {
  display: none;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -60px;
  width: 120px;
  padding-bottom: 20px;
  &::before {
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: 12px;
    left: 50%;
    z-index: 2;
    margin-left: -8px;
    transform: rotate(45deg);
    background-color: #fff;
    content: "";
  }
  .modal-container {
    position: relative;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 10px 0px rgba(153, 153, 153, 0.2);
    img {
      display: block;
      width: 100px;
      height: 100px;
    }
    p {
      padding-top: 5px;
      font-size: 12px;
      color: #555;
      text-align: center;
    }
  }
}
</style>