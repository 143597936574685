import { courseApi } from '@/api/course';
import { Spin } from 'view-design';

export const state = () => ({
  queryForm: {
    // 所属类目|可选
    categoryIdList: [],
    // 课程名称
    courseName: null,
    // 课程类型 COURSE_TYPE_ENUM
    courseType: null,
    //  是否免费
    freeFlag: null,
    // 是否查询总数
    isSearchCount: null,
    // 查询试听课程
    queryAuditionFlag: null,
    // 关键词
    searchWord: null,
    // 价格排序
    sortByPrice: null,
    // 销量排序
    sortBySales: null,
    // 排序字段集合
    sortItemList: null,
    // 用户标识|可选
    userIdentity: null,
    pageNum: 1,
    pageSize: 20
  },
  // 搜索结果
  courseList: [],
  // 搜索结果数量
  courseCount: 0,
  // 分页信息
  pageInfo: {
    pageNum: 1,
    pageSize: 20,
    pageTotal: 0
  }
});
export const getters = {};
export const mutations = {

  /**
     * 清空查询参数
     * @param state
     * @constructor
     */
  CLEAR_QUERY_FORM (state) {
    state.queryForm = {
      // 所属类目|可选
      categoryIdList: [],
      // 课程名称
      courseName: null,
      // 课程类型 COURSE_TYPE_ENUM
      courseType: null,
      //  是否免费
      freeFlag: null,
      // 是否查询总数
      isSearchCount: null,
      // 查询试听课程
      queryAuditionFlag: null,
      // 关键词
      searchWord: null,
      // 价格排序
      sortByPrice: null,
      // 销量排序
      sortBySales: null,
      // 排序字段集合
      sortItemList: null,
      // 用户标识|可选
      userIdentity: null,
      pageNum: 1,
      pageSize: 20
    };
  },

  // 设置专业信息
  SET_CATEGORY (state, categoryId) {
    if (categoryId) {
      state.queryForm.categoryIdList = [categoryId];
    } else {
      state.queryForm.categoryIdList = [];
    }
    state.queryForm.pageNum = 1;
  },
  // ------------------------ 设置 全部/有试听 begin ------------------------
  // 全部
  SET_QUERY_ALL (state) {
    state.queryForm.queryAuditionFlag = null;
    state.queryForm.pageNum = 1;
  },
  // 试听
  SET_QUERY_AUDITION (state) {
    state.queryForm.queryAuditionFlag = true;
    state.queryForm.pageNum = 1;
  },
  // ------------------------ 设置 全部/免费课/付费课/有试听 end ------------------------

  // 设置搜索关键字
  SET_SEARCH_WORD (state, data) {
    state.queryForm.searchWord = data;
    state.queryForm.pageNum = 1;
  },

  // 课程类型
  SET_COURSE_TYPE (state, data) {
    state.queryForm.courseType = data;
    state.queryForm.pageNum = 1;
  },

  // 设置销量排序 asc desc
  SET_ENROLLMENT_SORT (state, data) {
    state.queryForm.sortByPrice = null;
    if (data === null) {
      state.queryForm.sortBySales = null;
    } else {
      state.queryForm.sortBySales = data;
    }
    state.queryForm.pageNum = 1;
  },

  // 设置价格排序
  SET_PRICE_SORT (state, data) {
    state.queryForm.sortBySales = null;
    if (data === null) {
      state.queryForm.sortByPrice = null;
    } else {
      state.queryForm.sortByPrice = data;
    }
    state.queryForm.pageNum = 1;
  },

  // 搜索结果
  SET_COURSE_LIST (state, data) {
    state.courseList = data.list;
    state.courseCount = data.total;
    state.pageInfo.pageNum = data.pageNum;
    state.pageInfo.pageSize = data.pageSize;
    state.pageInfo.pageTotal = data.total;
  },

  // 设置分页参数
  SET_QUERY_PAGE_PARAM (state, data) {
    state.queryForm.pageNum = data.pageNum;
    state.queryForm.pageSize = data.pageSize;
  }

};

export const actions = {

  // 查询
  async QUERY_COURSE ({ commit, state }) {
    Spin.show();
    try {
      const res = await courseApi.queryList(state.queryForm);
      commit('SET_COURSE_LIST', res.data);
    } catch (e) {
      $nuxt.$smartSentry.captureException(e);
    }
    Spin.hide();
  }

};
