// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/_css-loader@5.2.7@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/_css-loader@5.2.7@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/image/index/new-year-header.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".new-year-header[data-v-52de8a0e]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% no-repeat;background-size:cover;cursor:pointer;height:50px;position:relative;transition:all .5s;width:100%}.new-year-header .close[data-v-52de8a0e]{cursor:pointer;height:22px;position:absolute;right:24px;top:14px;width:22px}.new-year-header.close[data-v-52de8a0e]{height:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
