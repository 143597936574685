/*
 * @Description: 页面刷新从localStorage中获取用户信息保存到Vuex
 * @version: 
 * @Author: 李云飞
 * @Date: 2020-09-26 11:01:26
 * @LastEditors: 李云飞
 * @LastEditTime: 2020-09-26 11:08:05
 */
export default function (ctx) {
  window.addEventListener('load', () => {
    ctx.store.commit('user/readUserInfo');
  });
}