<!--
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-20 14:49:38
 * @LastEditors: hanyu
 * @LastEditTime: 2021-03-24 16:34:49
-->
<template>
    <div class="root">
        <!-- 浮动客服 -->
        <FloatCustomerService></FloatCustomerService>
        <!-- 登录弹窗 -->
        <Account ref="accountRef"></Account>
        <nuxt />
    </div>
</template>

<script>
import NewYearHeader from '@/components/new-year-header';
import FloatCustomerService from '@/components/float-customer-service';
import Account from '@/components/account';
export default {
  name: 'DefaultLayouts',
  components: {
    FloatCustomerService,
    Account,
    NewYearHeader,
  },
  props: {},
  data() {
    return {
      showTopAd: false, // 是否显示顶部广告
    };
  },
  computed: {

  },
  watch: {},
  filters: {},
  created() {
  },
  mounted() {
    this.showTopAd = this.$route.name == 'index'
  },
  methods: {
  }
}
</script>
<style lang='less' scoped>
.head-top {
  width: 100%;
}
</style>
