/*
 * @Description: axios拦截器
 * @Author: hanyu
 * @Date: 2020-03-12 17:13:42
 * @LastEditTime: 2020-09-23 17:18:30
 * @LastEditors: 李云飞
 */
import cookie from '@/lib/cookie';
export default function ({
  $axios,
  redirect,
  req,
  store
}) {
  const isServer = process.server; // 是否在服务端
  const isClient = process.client; // 是否在客户端
  if (isServer && process.env.MODE != 'dev') {
    $axios.defaults.baseURL = 'http://127.0.0.1:10158'
  } else {
    $axios.defaults.baseURL = process.env.baseUrl
  }

  $axios.onRequest(config => {
    if (isServer) {
      if (req.headers.cookie) {
        let cookieArr = []
        if (req.headers.cookie.indexOf(';') > 0) {
          cookieArr = req.headers.cookie.split(';')
        } else {
          cookieArr = [req.headers.cookie]
        }
        let cookieStr = ''
        // 拿到cookie 里的token
        for (let cItem of cookieArr) {
          if (cItem.indexOf('cloud-token') >= 0) {
            let cArr = cItem.split('=')
            cookieStr = cArr[1]
            break;
          }
        }
        // 拿到cookie 里的token -- end

        // 赋值
        if (cookieStr && isClient) {
          config.headers['x-access-token'] = cookieStr
        } else {
          if (config.headers['x-access-token']) {
            delete config.headers['x-access-token']
          }
        }
      }
    }
    if (isClient) {
      if (cookie.getToken()) {
        config.headers['x-access-token'] = cookie.getToken() || '';
      }
    }
  })
  $axios.onResponse(res => {
    let { data } = res;
    if (data.code !== 1) {
      if (data.code === 14004) {
        this.$Message.error(data.msg)
        console.log('logn...no')
        var timeOut = setTimeout(() => {
          clearTimeout(timeOut)
          // 如果被挤掉，就直接清除 token 和 userInfo
          // 暂时注释，需要写登录逻辑的时候再打开
          // store.commit('login/clearLocalStorage')
          // store.commit('login/clearToken');
          // return redirect('/login')
        }, 2000);
      } else {
        if (res.config._ext && res.config._ext.showMessage === false) {
          //只有这种情况不显示
        } else {
          this.$Message.error(data.msg);
        }
      }
      if (this.$Spin) {
        this.$Spin.hide();
      }
      return Promise.reject(res);
    }
    return data;
  })
  $axios.onError(error => {
    if (this.$Spin) {
      this.$Spin.hide();
    }
    return Promise.reject(error);
  })
}

