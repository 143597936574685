<!--
 * @Description: 密码登录
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-26 08:44:38
 * @LastEditors: hanyu
 * @LastEditTime: 2020-10-08 13:49:30
-->
<template>
  <div>
    <div class="title">
      密码登录
    </div>
    <div class="change-login-type">
      <a
        href="javascript:;"
        @click="changeLoginType"
      >验证码登录</a>
    </div>
    <div class="form">
      <div class="form-row user-name">
        <div class="input-conent">
          <Input
            v-model.trim="formData.phone"
            placeholder="请输入用户名"
          />
        </div>
      </div>
      <div class="form-row pass">
        <div class="input-conent">
          <Input
            v-model.trim="formData.password"
            type="text"
            :style="{'webkit-text-security' : passHide ? 'disc' : 'unset'}"
            placeholder="请输入密码"
          />
        </div>
        <div
          class="toggle-pass"
          :class="{show: !passHide}"
          @click="passHide = !passHide"
        />
      </div>
      <div
        class="send-button"
        @click="loginBuyPass"
      >
        登 录
      </div>
    </div>
    <div class="forget-pass">
      <a
        href="javascript:;"
        @click="changeForgetPass"
      >忘记密码？</a>
    </div>

    <Spin
      v-if="isLoading"
      fix
    >
      <Icon
        type="ios-loading"
        size="36"
        class="demo-spin-icon-load"
      />
    </Spin>
  </div>
</template>

<script>
import { accountApi } from '@/api/account';
import { ACCOUNT_SHOW_TYPE } from '@/constant/account';
import { getSystemName, getBrowserName } from '@/lib/utils';
import { USER_TERMINAL_APP_TYPE_ENUM, USER_DEVICE_TYPE_ENUM } from '@/constant/account';

export default {
  name: 'AccountLoginPass',
  components: {},
  filters: {},
  props: {},
  data () {
    return {
      passHide: true,
      formData: {
        phone: null,
        password: null
      },
      isLoading: false,
      USER_TERMINAL_APP_TYPE_ENUM
    };
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
  },
  beforeDestroy () {
    this.unListenEnterKeyDown();
  },
  methods: {
    // 监听回车按下事件
    listenEnterKeyDown () {
      document.onkeydown = (e) => {
        const event = e || event;
        if (event.keyCode == 13) {
          event.preventDefault();
          this.loginBuyPass();
        }
      };
    },
    // 撤销监听回车按下事件
    unListenEnterKeyDown () {
      document.onkeydown = undefined;
    },
    // 切换到验证码登录
    changeLoginType () {
      this.$store.commit('user/changeAccountType', ACCOUNT_SHOW_TYPE.LOGIN_SMS.value);
    },

    // 切换到忘记密码
    changeForgetPass () {
      this.$store.commit('user/changeAccountType', ACCOUNT_SHOW_TYPE.FORGET_PASS.value);
    },

    // 点击登录
    async loginBuyPass () {
      try {
        this.isLoading = true;
        if (!this.formData.phone) {
          this.$Message.error('请输入用户名');
          return;
        }
        if (!this.formData.password) {
          this.$Message.error('请输入密码');
          return;
        }
        // 设备信息
        const systemName = getSystemName();
        // 浏览器名称
        const browserName = getBrowserName();
        const param = Object.assign(this.formData,
          {
            deviceType: USER_DEVICE_TYPE_ENUM.PC.value,
            deviceInfo: `${systemName}${browserName}`,
            terminalAppType: USER_TERMINAL_APP_TYPE_ENUM.PC_ZHI_KAO_DA_XUE.value
          });
        const result = await accountApi.loginBuyPass(param);
        this.$emit('loginSuccess', result.data);
        this.$Message.success('登录成功');
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },
    // 重置参数
    resetParams () {
      Object.assign(this.$data, this.$options.data.call(this));
    }

  }
};
</script>
<style lang='less' scoped>
  @import "./account.less";

  .forget-pass {
    margin-top: 15px;
    text-align: right;

    a {
      color: #999;
    }
  }
</style>
