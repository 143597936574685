/*
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-24 10:13:17
 * @LastEditors: hanyu
 * @LastEditTime: 2020-12-30 09:43:36
 */

//  登录弹窗显示类型
export const ACCOUNT_SHOW_TYPE = {
  LOGIN_PASS: {
    value: 1,
    desc: '密码登录'
  },
  LOGIN_SMS: {
    value: 2,
    desc: '验证码登录'
  },
  REGISTER: {
    value: 3,
    desc: '用户注册'
  },
  FORGET_PASS: {
    value: 4,
    desc: '忘记密码'
  }
};
export const
  TERMINAL_TYPE_ENUM =
    {
      ANDROID: {
        value: 1,
        desc: 'android'
      },
      IOS: {
        value: 2,
        desc: 'ios'
      },
      PC: {
        value: 3,
        desc: 'PC'
      },
      WAP: {
        value: 4,
        desc: 'H5'
      },
      MP: {
        value: 5,
        desc: '微信小程序'
      },
      WX_OFFICIAL_ACCOUNT: {
        value: 6,
        desc: '微信公众号'
      }
    };
export const
  USER_THIRD_TYPE_ENUM =
    {
      QQ: {
        value: 1,
        desc: 'QQ'
      },
      WE_CHAT: {
        value: 2,
        desc: '微信'
      },
      APPLE: {
        value: 3,
        desc: '苹果'
      }
    };
export const
  DEVICE_TYPE_ENUM =
    {
      ANDROID: {
        value: 1,
        desc: 'android'
      },
      IOS: {
        value: 2,
        desc: 'ios'
      },
      PC: {
        value: 3,
        desc: 'PC'
      }
    };

// 注册终端应用
export const USER_TERMINAL_APP_TYPE_ENUM = {
  MP_ZHI_KAO_DA_XUE: {
    value: 100,
    desc: '云平台-小程序'
  },
  H5_ZHI_KAO_DA_XUE: {
    value: 101,
    desc: '云平台-H5'
  },
  PC_ZHI_KAO_DA_XUE: {
    value: 201,
    desc: '云平台-PC'
  }
};

// 设备类型:
export const USER_DEVICE_TYPE_ENUM =
  {
    ANDROID: {
      value: 1,
      desc: 'android'
    },
    IOS: {
      value: 2,
      desc: 'ios'
    },
    PC: {
      value: 3,
      desc: 'PC'
    },
    UNKNOWN: {
      value: 44,
      desc: '未知'
    }
  };

export const
  USER_SOURCE_TYPE_ENUM =
    {
      FREEDOM: {
        value: 1,
        desc: '自由注册'
      },
      CREATE_ORDER: {
        value: 2,
        desc: '创建订单'
      },
      RECEIVE_COURSE: {
        value: 3,
        desc: '领取课程'
      },
      RECEIVE_GOODS: {
        value: 5,
        desc: '领取商品'
      },
      ADD: {
        value: 6,
        desc: '手动添加'
      }
    };
export default {
  ACCOUNT_SHOW_TYPE,
  TERMINAL_TYPE_ENUM,
  USER_THIRD_TYPE_ENUM,
  DEVICE_TYPE_ENUM,
  USER_TERMINAL_APP_TYPE_ENUM,
  USER_DEVICE_TYPE_ENUM,
  USER_SOURCE_TYPE_ENUM
};
