import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _590056a9 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _1ec6896e = () => interopDefault(import('../pages/app-download/index.vue' /* webpackChunkName: "pages/app-download/index" */))
const _4a9efb73 = () => interopDefault(import('../pages/certificate/index.vue' /* webpackChunkName: "pages/certificate/index" */))
const _79fa1155 = () => interopDefault(import('../pages/course/index.vue' /* webpackChunkName: "pages/course/index" */))
const _0f5db9c3 = () => interopDefault(import('../pages/face-special/index.vue' /* webpackChunkName: "pages/face-special/index" */))
const _42dc4e66 = () => interopDefault(import('../pages/in-development/index.vue' /* webpackChunkName: "pages/in-development/index" */))
const _5fe8ff85 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _bfd6fb42 = () => interopDefault(import('../pages/question-bank/index.vue' /* webpackChunkName: "pages/question-bank/index" */))
const _47654c7e = () => interopDefault(import('../pages/teacher/index.vue' /* webpackChunkName: "pages/teacher/index" */))
const _139e6104 = () => interopDefault(import('../pages/about/detail-info/index.vue' /* webpackChunkName: "pages/about/detail-info/index" */))
const _08900bb3 = () => interopDefault(import('../pages/agreement/privacy-policy.vue' /* webpackChunkName: "pages/agreement/privacy-policy" */))
const _7864a6a4 = () => interopDefault(import('../pages/agreement/user-agreement.vue' /* webpackChunkName: "pages/agreement/user-agreement" */))
const _6a1110e1 = () => interopDefault(import('../pages/course/detail/index.vue' /* webpackChunkName: "pages/course/detail/index" */))
const _61971774 = () => interopDefault(import('../pages/question-bank/index copy.vue' /* webpackChunkName: "pages/question-bank/index copy" */))
const _2068b423 = () => interopDefault(import('../pages/teacher/teacher-detail/index.vue' /* webpackChunkName: "pages/teacher/teacher-detail/index" */))
const _1782974e = () => interopDefault(import('../pages/agreement/components/ColumnName.vue' /* webpackChunkName: "pages/agreement/components/ColumnName" */))
const _02d4333a = () => interopDefault(import('../pages/agreement/components/header.vue' /* webpackChunkName: "pages/agreement/components/header" */))
const _369db4e2 = () => interopDefault(import('../pages/course/components/course-class.vue' /* webpackChunkName: "pages/course/components/course-class" */))
const _c117f1e4 = () => interopDefault(import('../pages/course/components/course-class-card/index.vue' /* webpackChunkName: "pages/course/components/course-class-card/index" */))
const _7ef80e38 = () => interopDefault(import('../pages/course/components/filter-row/index.vue' /* webpackChunkName: "pages/course/components/filter-row/index" */))
const _59f990be = () => interopDefault(import('../pages/course/components/mixins/index.js' /* webpackChunkName: "pages/course/components/mixins/index" */))
const _7ab9ebd4 = () => interopDefault(import('../pages/course/components/share-modal/index.vue' /* webpackChunkName: "pages/course/components/share-modal/index" */))
const _ef46764a = () => interopDefault(import('../pages/course/components/transition/index.js' /* webpackChunkName: "pages/course/components/transition/index" */))
const _7c98b324 = () => interopDefault(import('../pages/question-bank/components/filter-row/index.vue' /* webpackChunkName: "pages/question-bank/components/filter-row/index" */))
const _604f06d9 = () => interopDefault(import('../pages/question-bank/components/question-class-card/index.vue' /* webpackChunkName: "pages/question-bank/components/question-class-card/index" */))
const _04ec031e = () => interopDefault(import('../pages/course/detail/components/top-detail.vue' /* webpackChunkName: "pages/course/detail/components/top-detail" */))
const _3b7e2614 = () => interopDefault(import('../pages/course/components/filter-row/components/sort-title.vue' /* webpackChunkName: "pages/course/components/filter-row/components/sort-title" */))
const _45457f9e = () => interopDefault(import('../pages/question-bank/components/filter-row/components/sort-title.vue' /* webpackChunkName: "pages/question-bank/components/filter-row/components/sort-title" */))
const _0b7c3f6b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e4554cf0 = () => interopDefault(import('../pages/index/components/books/index.vue' /* webpackChunkName: "pages/index/components/books/index" */))
const _7241e4da = () => interopDefault(import('../pages/index/components/course/index.vue' /* webpackChunkName: "pages/index/components/course/index" */))
const _7e4fb9ab = () => interopDefault(import('../pages/index/components/course-section/index.vue' /* webpackChunkName: "pages/index/components/course-section/index" */))
const _690380fc = () => interopDefault(import('../pages/index/components/free-section/index.vue' /* webpackChunkName: "pages/index/components/free-section/index" */))
const _766bdd3f = () => interopDefault(import('../pages/index/components/index-banner/index.vue' /* webpackChunkName: "pages/index/components/index-banner/index" */))
const _fef70908 = () => interopDefault(import('../pages/index/components/live-section/index.vue' /* webpackChunkName: "pages/index/components/live-section/index" */))
const _54667022 = () => interopDefault(import('../pages/index/components/section-major/index.vue' /* webpackChunkName: "pages/index/components/section-major/index" */))
const _47b49166 = () => interopDefault(import('../pages/index/components/select-major-and-school/index.vue' /* webpackChunkName: "pages/index/components/select-major-and-school/index" */))
const _d1c05000 = () => interopDefault(import('../pages/index/components/teacher/index.vue' /* webpackChunkName: "pages/index/components/teacher/index" */))
const _359925b0 = () => interopDefault(import('../pages/index/components/voice-section/index.vue' /* webpackChunkName: "pages/index/components/voice-section/index" */))
const _764cc084 = () => interopDefault(import('../pages/index/components/course-section/child-major-modal/index.vue' /* webpackChunkName: "pages/index/components/course-section/child-major-modal/index" */))
const _0316258e = () => interopDefault(import('../pages/index/components/course-section/data.js' /* webpackChunkName: "pages/index/components/course-section/data" */))
const _2d4ad76a = () => interopDefault(import('../pages/index/components/voice-section/data.js' /* webpackChunkName: "pages/index/components/voice-section/data" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _590056a9,
    name: "about"
  }, {
    path: "/app-download",
    component: _1ec6896e,
    name: "app-download"
  }, {
    path: "/certificate",
    component: _4a9efb73,
    name: "certificate"
  }, {
    path: "/course",
    component: _79fa1155,
    name: "course"
  }, {
    path: "/face-special",
    component: _0f5db9c3,
    name: "face-special"
  }, {
    path: "/in-development",
    component: _42dc4e66,
    name: "in-development"
  }, {
    path: "/login",
    component: _5fe8ff85,
    name: "login"
  }, {
    path: "/question-bank",
    component: _bfd6fb42,
    name: "question-bank"
  }, {
    path: "/teacher",
    component: _47654c7e,
    name: "teacher"
  }, {
    path: "/about/detail-info",
    component: _139e6104,
    name: "about-detail-info"
  }, {
    path: "/agreement/privacy-policy",
    component: _08900bb3,
    name: "agreement-privacy-policy"
  }, {
    path: "/agreement/user-agreement",
    component: _7864a6a4,
    name: "agreement-user-agreement"
  }, {
    path: "/course/detail",
    component: _6a1110e1,
    name: "course-detail"
  }, {
    path: "/question-bank/index%20copy",
    component: _61971774,
    name: "question-bank-index copy"
  }, {
    path: "/teacher/teacher-detail",
    component: _2068b423,
    name: "teacher-teacher-detail"
  }, {
    path: "/agreement/components/ColumnName",
    component: _1782974e,
    name: "agreement-components-ColumnName"
  }, {
    path: "/agreement/components/header",
    component: _02d4333a,
    name: "agreement-components-header"
  }, {
    path: "/course/components/course-class",
    component: _369db4e2,
    name: "course-components-course-class"
  }, {
    path: "/course/components/course-class-card",
    component: _c117f1e4,
    name: "course-components-course-class-card"
  }, {
    path: "/course/components/filter-row",
    component: _7ef80e38,
    name: "course-components-filter-row"
  }, {
    path: "/course/components/mixins",
    component: _59f990be,
    name: "course-components-mixins"
  }, {
    path: "/course/components/share-modal",
    component: _7ab9ebd4,
    name: "course-components-share-modal"
  }, {
    path: "/course/components/transition",
    component: _ef46764a,
    name: "course-components-transition"
  }, {
    path: "/question-bank/components/filter-row",
    component: _7c98b324,
    name: "question-bank-components-filter-row"
  }, {
    path: "/question-bank/components/question-class-card",
    component: _604f06d9,
    name: "question-bank-components-question-class-card"
  }, {
    path: "/course/detail/components/top-detail",
    component: _04ec031e,
    name: "course-detail-components-top-detail"
  }, {
    path: "/course/components/filter-row/components/sort-title",
    component: _3b7e2614,
    name: "course-components-filter-row-components-sort-title"
  }, {
    path: "/question-bank/components/filter-row/components/sort-title",
    component: _45457f9e,
    name: "question-bank-components-filter-row-components-sort-title"
  }, {
    path: "/",
    component: _0b7c3f6b,
    name: "index",
    children: [{
      path: "components/books",
      component: _e4554cf0,
      name: "index-components-books"
    }, {
      path: "components/course",
      component: _7241e4da,
      name: "index-components-course"
    }, {
      path: "components/course-section",
      component: _7e4fb9ab,
      name: "index-components-course-section"
    }, {
      path: "components/free-section",
      component: _690380fc,
      name: "index-components-free-section"
    }, {
      path: "components/index-banner",
      component: _766bdd3f,
      name: "index-components-index-banner"
    }, {
      path: "components/live-section",
      component: _fef70908,
      name: "index-components-live-section"
    }, {
      path: "components/section-major",
      component: _54667022,
      name: "index-components-section-major"
    }, {
      path: "components/select-major-and-school",
      component: _47b49166,
      name: "index-components-select-major-and-school"
    }, {
      path: "components/teacher",
      component: _d1c05000,
      name: "index-components-teacher"
    }, {
      path: "components/voice-section",
      component: _359925b0,
      name: "index-components-voice-section"
    }, {
      path: "components/course-section/child-major-modal",
      component: _764cc084,
      name: "index-components-course-section-child-major-modal"
    }, {
      path: "components/course-section/data",
      component: _0316258e,
      name: "index-components-course-section-data"
    }, {
      path: "components/voice-section/data",
      component: _2d4ad76a,
      name: "index-components-voice-section-data"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
