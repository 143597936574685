<!--
 * @Description: 登录弹窗
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-26 08:26:49
 * @LastEditors: 李云飞
 * @LastEditTime: 2020-10-22 10:54:13
-->
<template>
  <Modal class-name="account"
         :value="showAccountModal"
         width="430"
         footer-hide
         @on-visible-change="onVisibleChange">
    <AccountContainer ref="accountContainer" @success="loginSuccess"></AccountContainer>
  </Modal>
</template>

<script>
import AccountContainer from './components/account-container';

export default {
  name: 'Account',
  components: {
    AccountContainer
  },
  filters: {},
  props: {},
  data () {
    return {};
  },
  computed: {
    showAccountModal () {
      return this.$store.state.user.showAccountModal;
    }
  },
  watch: {
    showAccountModal (val) {
      if (val) {
        this.$refs.accountContainer.initListenEnterKeyDown();
      } else {
        this.$refs.accountContainer.unListenEnterKeyDown();
        this.$refs.accountContainer.resetParams();
      }
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    // 登录成功
    loginSuccess () {
      this.$store.commit('user/changeAccountModal', { isShow: false });
    },
    // 显示状态发生变化时触发
    onVisibleChange (value) {
      if (!value) {
        this.$store.commit('user/changeAccountModal', { isShow: false });
      }
    }
  }
};
</script>
<style lang='less' scoped>
/deep/ .account {
  display: flex;

  .ivu-modal {
    top: 0;
    margin: auto;

    .ivu-modal-content {
      width: 430px;
      height: 550px;
      border-radius: 4px;

      .ivu-modal-body {
        padding: 40px;
        height: 100%;
      }
    }
  }
}
</style>
