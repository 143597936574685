/*
 * @Description:
 * @Author: hanyu
 * @Date: 2020-05-28 14:28:44
 * @LastEditTime: 2020-09-21 14:40:54
 * @LastEditors: hanyu
 */
// smart sentry
import * as Sentry from '@sentry/browser';
import Vue from 'vue';
// require('../static/js/Browser.js')
const smartSentry = {
  install (Vue) {
    Vue.prototype.$smartSentry = {
      /**
         * sentry 主动上报
         * @param {error} error 错误信息
         */
      captureException: (error) => {
        console.log(error);
        if (error.config && error.data && error && error.headers && error.request && error.status) {
          return;
        }
        Sentry.captureException(error);
      }
    };
  }
};
// // 为sentry添加额外的附加信息（浏览器类型及自定义的信息）
// Sentry.configureScope((scope) => {
//   scope.setExtra('浏览器', new Browser());
// });

Vue.use(smartSentry);
