/*
 * @Description: 判断环境跳转到h5
 * @Author: 康蔚明
 * @LastEditors: 康蔚明
 * @Date: 2020-09-30 11:42:08
 * @LastEditTime: 2020-09-30 11:42:21
 */

export default function (context) {
  context.userAgent = process.server ? context.req.headers['user-agent'] : navigator.userAgent;
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(context.userAgent)) {
    context.redirect(301, process.env.H5_URL);
  }
}
