<!--
 * @Description: 首页 全部课程  等导航
 * @Author: hanyu
 * @Date: 2020-09-20 16:24:31
 * @LastEditTime: 2020-10-22 14:48:40
 * @LastEditors: hanyu
-->
<template>
  <div class="header-row">
    <div class="header-box">
      <div style="display: flex;align-items: center">
        <div class="company-info">
          <nuxt-link class="logo" :style="`background-image: url('${getLogoUrl}')`"
                     to="/"></nuxt-link>
          <div>{{getSiteName}}</div>
        </div>
        <!-- 主要菜单 -->
        <MainMenu></MainMenu>
      </div>
      <!-- 右侧用户信息 -->
      <UserOption></UserOption>
    </div>
  </div>
</template>

<script>
import { companyApi } from '@/api/company.js';
import MainMenu from '../main-menu';
import UserOption from '../user-option';
export default {
  name: 'MainHeader',
  components: { MainMenu, UserOption },
  filters: {},
  props: {},
  data () {
    return {

    };
  },
  computed: {
    // 获取logoUrl
    getLogoUrl () {
      const companyInfo = this.$store.state.companyInfo || {};
      return companyInfo.companyLogo;
    },
    // 获取公司名称
    getSiteName () {
      const companyInfo = this.$store.state.companyInfo || {};
      return companyInfo.siteName;
    }
  },
  watch: {},
  created () {
  },
  mounted () {
    this.getCompanyInfo();
  },
  methods: {
    //
    async getCompanyInfo () {
      console.log(' nuxt 初始化渲染获得网站基本信息')
      let result = await companyApi.getCompanyInfo()
      this.$store.commit('SET_COMPANY_INFO', result.data)
      
      // 更改项目标签页的图标
      console.log('公司数据2：',JSON.stringify(result.data.companyLogo));
      var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = result.data.companyLogo || '/favicon.ico';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  }
};
</script>
<style lang='less' scoped>
.header-row {
  background: #ffffff;
  overflow: hidden;
  .header-box {
    width: 1200px;
    margin: 0 auto;
    padding: 21px 0;
    justify-content: space-between;
    display: flex;
    align-items: center;

    .company-info {
      display: flex;
      margin-right: 88px;
      font-size: 24px;
      font-weight: 800;
      color: #333333;
      line-height: 42px;
      letter-spacing: 1px;
      .logo {
        display: block;
        width: 42px;
        height: 42px;
        margin-right: 10px;
        background-size: cover;
      }
    }
  }
}
</style>
