import Vue from 'vue';
import lodash from 'lodash';

/**
 * 路径
 */
const ORIGIN = process.env.NUXT_ENV_MODE === 'dev' || process.env.NUXT_ENV_MODE === 'local' ? 'https://sit-manage.renminyixue.cn' : window.location.origin;
const PATH = '/pc-center/';
const URL_PATH = `${ORIGIN}${PATH}`;

/**
 * 老PC路由跳转
 */
const oldPcRouter = {
  install (Vue) {
    const OLD_PC_ROUTER = {};

    OLD_PC_ROUTER.push = ({
      path,
      query
    }) => {
      let url = `${URL_PATH}${path}`;
      if (!lodash.isEmpty(query)) {
        url += '?';
        const queryList = [];
        for (const key of Object.keys(query)) {
          queryList.push(`${key}=${query[key]}`);
        }
        url += queryList.join('&');
      }
      // window.location.href = url;
      const newWindow = window.open(); // 先打开页面
      newWindow.location = url; // 后更改页面地址
    };
    Vue.prototype.$oldPcRouter = OLD_PC_ROUTER;
  }
};

Vue.use(oldPcRouter);
