/*
 * @Description:订单相关枚举
 * @Author: hanyu
 * @Date: 2020-12-11 15:22:20
 * @LastEditTime: 2020-12-11 15:28:33
 * @LastEditors: hanyu
 */

// 自定义跳转到结算台枚举
export const
  SHOP_ORDER_ENUM =
{
  GOOD_DETAIL: {
    value: 1,
    desc: '从商品详情跳转'
  },
  SHOP_CART: {
    value: 2,
    desc: '从购物车跳转'
  }
};

export default {
  SHOP_ORDER_ENUM
};
